<template>
    <select
        v-bind="$attrs"
        :value="value"
        v-on="inputListeners">
        <option v-for="option in options"
                :key="option[labelBy]"
                :value="String(option[labelBy])" >
            {{ option[trackBy] }}
        </option>
    </select>
</template>

<script>
export default {
  name: "sai-select",
  model: {
    prop: 'value',
    event: 'change'
  },
  inheritAttrs: false,
  props: {
       options: { type: Array, default: () => []},
       value: { type: String, default: 'key'},
       labelBy: {type:String, default: 'key' },
       trackBy: {type:String, default: 'val' },
  },
  computed: {
    inputListeners: function () {
      var vm = this
      return Object.assign({},
        // We add all the listeners from the parent
        this.$listeners,
        {
          // This ensures that the component works with v-model
          change: function (event) {
            vm.$emit('change', String(event.target.value))
          }
        }
      )
    }
  }
};
</script>
