<template>
    <div v-if="getItem('showMainChatWindow')" class="chat-box">
        <Loading :active="getItem('loading')"
            :is-full-page="false" loader="spinner" color='black' :width="40" :height="40"
            :can-cancel="false"
            :lock-scroll="true"
            backgroundColor="white"
            :opacity="0.9"
            :z-index=999
        />
        <div class="chat-toolbar">
            <div class="previous-chats" @click.prevent="handleShowPreviousChats">Previous chats</div>
            <div class="new-chat" @click.prevent="handleNewChat">New chat</div>
            <div class="close-chat" @click.prevent="handleCloseChat">X</div>
        </div>
        <div v-if="getItem('showChatMessageWindow')" class="chat-window">
            <div class="chat-body">
                <div v-if="!getItem('loading')" class="chat-message-wrapper assistant">
                    <div class="chat-message">Hi! I'm I2I Assistant, a digital agent that can give instant answers to your questions.</div>
                </div>
                <template v-for="(message, index) in getItem('chatMessages')">
                    <div  v-if="message.role!='system'" :key="index" :class="['chat-message-wrapper', message.role]">
                        <div v-if="message.role=='user'" class="chat-message"> {{ message.content }}</div>
                        <div v-if="message.role=='assistant'" class="chat-message" v-html="getMessageWithCitation(message).content"></div>
                    </div>
                </template>
                <div v-if="getItem('waitingReply')" class="chat-message-wrapper assistant">
                    <div class="loader">
                        <span></span><span></span><span></span>
                    </div>
                </div>
            </div>
            
            <div class="chat-message-box">
                <div class="chat-message-txt">
                    <SaiTextArea :disabled="getItem('waitingReply')" placeholder="Enter text here" name="userMessage" id="usermessage" :value="getItem('userMessage')" @input="$event => setItem({field: 'userMessage', value: $event})" autocomplete="off" @keydown.enter.exact.prevent="handleSendMessage"/>
                </div>
                <div class="chat-send-button-wrapper">
                    <div class="chat-send-button"  @click.prevent="handleSendMessage">
                        <img src="/../common/img/arrow-forward.svg">
                    </div>
                </div>
            </div>
        </div>
        <div v-if="getItem('showPreviousChatList')" class="previous-chat-list">
            <div v-if="getPreviousItemChatList().length==0">
                No previous chat. Click New chat to start new conversation.
            </div>
            <div v-for="(chat, index) in getPreviousItemChatList()" :key="index" :class="['chat-list-item', (chat.id == getItem('chatId')) ? 'active': '']">
                <div class="chat-history-title" @click.prevent="handleLoadPreviousChat(chat)">{{ chat.title || chat.newTitle }}</div>
                <div class="delete-previous-chat" title="Delete this chat history" @click.prevent="handleDeleteChatHistory(chat.id)">x</div>
            </div>
        </div>
    </div>
</template>

<script>
    import Loading from 'vue-loading-overlay';
    import SaiTextArea from '@/components/common/SaiTextArea';
    import { createNamespacedHelpers } from 'vuex';
    const { mapActions: aiChatMapActions, mapGetters: aiChatMapGetters } = createNamespacedHelpers('aichat')
    export default {
        name: "index",
        props: {
            docId: { type: String,  default: '' },
            itemId: { type: String, default: '' },
            userId: { type: String,  default: '' },
            chatId: { type: String, default: '' }
        },
        components: {
            SaiTextArea,
            Loading
        },
        data() {
            return {
                isVisibleTourGuide: false
            }
        },
        computed: {
            ...aiChatMapGetters([
                'getItem',
                'getPreviousChatList'
            ])
        },
        methods: {
            ...aiChatMapActions([
                'setItem',
                'sendMessageToAI',
                'loadPreviousChatList',
                'loadPreviousChat',
                'loadNewChat',
                'closeChat',
                'showPreviousChatList',
                'deleteChatHistory'
            ]),
            handleLoadPreviousChat(chat) {
                this.loadPreviousChat(chat);
            },
            handleCloseChat() {
                this.closeChat();
            },
            handleShowPreviousChats() {
                this.showPreviousChatList();
            },
            handleNewChat() {
                this.loadNewChat({ docId: this.getItem('docId'), itemId: this.getItem('itemId'), userId: this.getItem('userId'), resId: this.getItem('resId'), pubId: this.getItem('pubId')});
            },
            handleSendMessage() {
                let message = this.getItem('userMessage');
                if(message.trim()!=='') {
                    this.setItem({field: 'userMessage', value: ''});
                    this.sendMessageToAI(message);
                }
            },
            getPreviousItemChatList() {
                return this.getPreviousChatList({id: this.getItem('itemId'), type: 'item'});
            },
            handleDeleteChatHistory(chatId) {
                this.deleteChatHistory(chatId);
                return false;
            },
            getMessageWithCitation: function(message) {
                if(message.citations && message.citations.length) {
                    const docId = this.getItem('docId');
                    const itemId = this.getItem('itemId');
                    const resId = this.getItem('resId');
                    const pubId = this.getItem('pubId');
                    let citations = [];
                    let messageContent = message.content;
                    messageContent.replace(/\[doc(\d)\]/g, function(match)  {
                        citations.push(match.slice(1, -1));
                        return match;
                    });
                    let citationSeqNo = 1;
                    let citationLinks = "";
                    citations = citations.sort();
                    let prevCitation = "";
                
                    citations.forEach(function(item) {
                        if (item != prevCitation) {
                            var replace = "\\["+item+"\\]";
                            var regex = new RegExp(replace,"g");
                            messageContent = messageContent.replace(regex, '<sup>' + citationSeqNo +'</sup>');
                            if(message.citations.length) {
                                let citationIndex = parseInt(item.replace('doc',''))-1;
                                let page = 0;
                                if(message.citations[citationIndex] && message.citations[citationIndex]['metadata']) {
                                    page = message.citations[citationIndex]['metadata']['page'];
                                } else if (message.citations[citationIndex] && message.citations[citationIndex]['title'] && message.citations[citationIndex]['title']['page']) {
                                    page = message.citations[citationIndex]['title']['page'];
                                }
                                page = page + 1;
                                citationLinks += '<a target="_blank" href="/management/display/citations/' + docId + '/' + itemId + '/' + resId + '/' + pubId + '/' + page + '">Citation ' + citationSeqNo + '</a></br>';
                            }
                            citationSeqNo++;
                            prevCitation=item;
                        }
                    });
                    if(citationLinks) {
                        messageContent = messageContent + "</br></br></br>References:-<br>" + citationLinks;
                    }
                    message.content = messageContent;
                }
                return message;
            }
        }
    }
</script>

<style lang="scss">
@import "aichat";
</style>