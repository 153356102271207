<template>
    <div v-if="getItem('showCompareWindow')" class="pdf-compare-box">
        <Loading :active="getItem('loading')"
            :is-full-page="false" loader="spinner" color='black' :width="40" :height="40"
            :can-cancel="false"
            :lock-scroll="true"
            backgroundColor="white"
            :opacity="0.9"
            :z-index=999
        />
        <div :class="['compare-box']">
            <div :class="['compare-body', getItem('loading') ? 'no-visible': '']">
                <div class="compare-header">
                    <div :class="['compare-header-title', showTOCSelectoin ? '': 'hide']">Select sections to compare.</div>
                    <div class="compare-header-spacer">&nbsp;</div>
                    <div class="compare-header-prompt-selection" :class="[ getItem('showError') ? 'hide': '' ]">
                        <SaiSelect
                            :value ="getItem('selectedPromptId')"
                            name="compare_prompt" 
                            labelBy="id"
                            trackBy="title"
                            :options="getItem('promptOptions')"
                            placeholder="Select prompt..." 
                            @change="handleSelectPrompt"/>
                    </div>
                </div>
                <div :class="['section-selection', showTOCSelectoin ? '': 'hide']">
                    <div class="toc-wrapper">
                        <div class="toc-title">{{ getTOCArtefactName(1) }}</div>
                        <div class="toc-left">
                            <TOC v-for="section in getTOC(1)" :key="section.id" :node="section" :selected-item="getItem('toc1Selection')" @titleClick="(tocItem) => handleTitleClick(tocItem, 0)"></TOC>
                        </div>
                    </div>
                    <div class="toc-wrapper">
                        <div class="toc-title">{{  getTOCArtefactName(2) }}</div>
                        <div class="toc-right">
                            <TOC v-for="section in getTOC(2)" :key="section.id" :node="section" :selected-item="getItem('toc2Selection')" @titleClick="(tocItem) => handleTitleClick(tocItem, 1)"></TOC>
                        </div>
                    </div>
                </div>
                <VueShowdown :class="['compare-summary', showCompareSummary ? '': 'hide']" :markdown="this.getItem('diffSummary')" flavor="github" :options="{ emoji: true }"/>
                <div :class="['compare-error', getItem('showError') ? '': 'hide']" v-html="getItem('errMessage')"></div>
            </div>
            <div class="compare-cta">
                <div :class="['compare-rating', showRatingOption ? '': 'hide']">
                        Rate the response: &nbsp;
                        <SaiSelect
                            :disabled="getItem('updateRatingInProgress')"
                            :value="getItem('selectedRating')"
                            name="select_rating" 
                            labelBy="id"
                            trackBy="text"
                            :options="[{id: 0, text: 'Select rating...'}, {id: 1, text: 1}, {id: 2, text: 2}, {id: 3, text: 3}, {id: 4, text: 4}, {id: 5, text: 5}]"
                            placeholder="Select rating..." 
                            @change="handleSelectRating"/>
                </div>
                <div class="btn-spacer">&nbsp;</div>
                <div :class="['btn', 'btn-copy', showCopyButton ? '': 'hide']" @click.prevent="handleCopyToClipBoard">Copy To Clipboard</div>
                <div :class="['btn', showBackButton ? '': 'hide']"  :style="{'z-index': 1000}" @click.prevent="handleBack">Back</div>
                <div :class="['btn', 'btn-compare', activateCompareButton ? 'active': '',  showCompareButton ? '': 'hide']" @click.prevent="handleCompare">Compare</div>
                <div :class="['btn', showRegenerateSummary ? '': 'hide']" @click.prevent="handleCompare">Regenerate Compare Summary</div>
                <div class="btn" @click.prevent="handleClose" :style="{ 'z-index': 1000}">Close</div>
            </div>
        </div>
    </div>
</template>

<script>
    import SaiSelect from '@/components/common/SaiSelect';
    import Loading from 'vue-loading-overlay';
    import TOC from './TOC'
    import { createNamespacedHelpers } from 'vuex';
    const { mapActions: aiCompareMapActions, mapGetters: aiCompareMapGetters } = createNamespacedHelpers('aicompare')
    export default {
        name: "index",
        components: {
            Loading,
            TOC,
            SaiSelect
        },
        computed: {
            ...aiCompareMapGetters([
                'getItem',
                'showTOCSelectoin',
                'showCompareSummary',
                'showCopyButton',
                'showBackButton',
                'showCompareButton',
                'activateCompareButton',
                'showRatingOption',
                'showRegenerateSummary'
            ]),
            compareReady: function() {
                return (typeof this.getItem('toc1Selection').id !== "undefined") && (typeof this.getItem('toc2Selection').id !== "undefined");
            }
        },
        methods: {
            ...aiCompareMapActions([
                'setItem',
                'closeCompare',
                'getSecitonDiffSummary',
                'updateSummaryRating'
            ]),
            handleCloseCompare() {
                this.closeCompare();
            },
            getTOC: function(index) {
                let toc = 'toc' + index;
                if(typeof this.getItem(toc) != "undefined") {
                    return this.getItem(toc)['toc'];
                }
                return [];
            },
            getTOCArtefactName: function(index) {
                let toc = 'toc' + index;
                if(typeof this.getItem(toc) != "undefined") {
                    return this.getItem(toc)['artefact_name'];
                }
                return '';
            },
            handleTitleClick(tocItem, tocIndex) {
                if(tocIndex == 0) {
                    this.setItem({field: 'toc1Selection', value: tocItem});
                }
                if(tocIndex == 1) {
                    this.setItem({field: 'toc2Selection', value: tocItem});
                }
            },
            abortApiCall() {
                let checkDiffStatusApi = this.getItem('abortController.checkDiffStatusApi');
                if(checkDiffStatusApi) {
                    checkDiffStatusApi.abort();
                }
                let compareApi = this.getItem('abortController.compareApi');
                if(compareApi) {
                    compareApi.abort();
                }
                let loadTOCApi = this.getItem('abortController.loadTOCApi');
                if(loadTOCApi) {
                    loadTOCApi.abort();
                }
            },
            handleBack() {
                this.setItem({field: 'loading', value: false})
                this.setItem({field: 'diffSummaryId', value: 0})
                this.setItem({field: 'selectedRating', value: 0})
                this.setItem({field: 'showTOCSelection', value: true})
                this.setItem({field: 'showCompareSummary', value: false})
                this.setItem({field: 'showError', value: false})
                this.setItem({field: 'processAborted', value: true});
                this.abortApiCall();
            },
            handleClose() {
                this.setItem({field: 'loading', value: false})
                this.setItem({field: 'diffSummaryId', value: 0})
                this.setItem({field: 'selectedRating', value: 0})
                this.setItem({field: 'toc1Selection', value: {}})
                this.setItem({field: 'toc2Selection', value: {}})
                this.setItem({field: 'showCompareWindow', value: false});
                this.setItem({field: 'processAborted', value: true});
                this.abortApiCall();
            },
            handleCompare() {
                if(this.compareReady) {
                    this.setItem({field: 'processAborted', value: false});
                    let compareContent = []
                    compareContent.push({
                        itemId: this.getItem('toc1').uid,
                        startPage: this.getItem('toc1Selection').startPage,
                        endPage: this.getItem('toc1Selection').endPage,
                        title: this.getItem('toc1Selection').title,
                        itemName: this.getItem('itemName1')
                    });
                    compareContent.push({
                        itemId: this.getItem('toc2').uid,
                        startPage: this.getItem('toc2Selection').startPage,
                        endPage: this.getItem('toc2Selection').endPage,
                        title: this.getItem('toc2Selection').title,
                        itemName: this.getItem('itemName2')
                    });
                    const selectedPromptId = this.getItem('selectedPromptId');
                    const docId = this.getItem('docId');
                    const actionedBy = this.getItem('userId');
                    const docName = this.getItem('docName');
                    const language = this.getItem('language');
                    this.getSecitonDiffSummary({ compareContent, selectedPromptId, docId, actionedBy, docName, language });
                    this.setItem({field: 'selectedRating', value: 0})
                }
            },
            handleSelectPrompt(event) {
                this.setItem({field: 'selectedPromptId', value: event});
            },
            handleSelectRating(event) {
                this.setItem({field: 'selectedRating', value: event});
                this.updateSummaryRating({ summaryId: this.getItem('diffSummaryId'), rating: this.getItem('selectedRating')});
            },
            handleCopyToClipBoard() {
                var copyText = this.getItem('diffSummary');
                navigator.clipboard.writeText(copyText);
            }
        }
    }
</script>

<style lang="scss">
@import "aicompare";
</style>